import styled from 'styled-components';

export const RazoesTitle = styled.div`
    font-weight: bold;
    color: #bd1622ff;
    text-align: center;
    padding-top: 5%;
`

export const SecondTitle = styled.div`
    color: #bd1622ff;
    text-align: center;
`

export const BoldTitle = styled.div`
    
    h2{
        font-weight: bold;
        text-align: center;
        color: #04273bff;
        padding-top: 2%;
        padding-bottom: 2%;
    }
`

export const Problemas = styled.div``

export const PersonBody = styled.div`
    
    .row{
        padding-top: 3%;
        padding-bottom: 3%;
        background-color: #04273bff;
    }
`

export const PersonTitle = styled.h2`
    color: #fff;
    font-weight: bold;
`
export const PersonSubTitle = styled.div`
    font-weight: bold;
    color: #82eb5cff;
`

export const PersonDescription = styled.p`
    color: #fff;
    padding-bottom: 5%;
`

export const ProblemasBtn = styled.div`
    a{
        border: 0px !important;
        font-weight: bold !important;
        width: 100%;
        margin-bottom: 5ch;
        padding-top: 1ch;
        padding-bottom: 1ch;
        border-radius: 25px !important;
        background: #00e78d;
        background: -moz-linear-gradient(left, #00e78d 0%, #00f774 50%, #50ff56 100%);
        background: -webkit-linear-gradient(
        left,
        #00e78d 0%,
        #00f774 50%,
        #50ff56 100%
        );
        background: linear-gradient(to right, #00e78d 0%, #00f774 50%, #50ff56 100%);
    }
`