import styled from 'styled-components';

export const Main = styled.div`
    .main-nav{
        background-color: #04273bff;
        min-height: 20vh;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: white !important;
        font-weight: bold;
    }

    .navbar-button{
        font-weight: bold !important;
        border-radius: 25px !important;
        border: none !important;
        width: fit-content;
        background: #00e78d;
        background: -moz-linear-gradient(left, #00e78d 0%, #00f774 50%, #50ff56 100%);
        background: -webkit-linear-gradient(
        left,
        #00e78d 0%,
        #00f774 50%,
        #50ff56 100%
        );
        background: linear-gradient(to right, #00e78d 0%, #00f774 50%, #50ff56 100%);
    }
`

export const Line = styled.div`
    height: 16px;
    background: #00e78d;
    background: -moz-linear-gradient(left, #00e78d 0%, #00f774 50%, #50ff56 100%);
    background: -webkit-linear-gradient(
    left,
    #00e78d 0%,
    #00f774 50%,
    #50ff56 100%
    );
    background: linear-gradient(to right, #00e78d 0%, #00f774 50%, #50ff56 100%);
`

export const ImgsContainer = styled.div`
    display:flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #04273bff;
    padding-top: 8px;
   
    a{
        padding: 5px;
        cursor: pointer;
    }
`