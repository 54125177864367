import React, { Component } from "react";
import YouTube from "react-youtube";
import "./video.css";

class Video extends Component {
    render() {
        const videoId = this.props.videoId;

        const opts = {
            playerVars: {
                autoplay: 0,
            },
        };

        return (
            <YouTube
                className="youtube"
                videoId={videoId}
                opts={opts}
                onReady={this._onReady}
            />
        );
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
}

export default Video;
