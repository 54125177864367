export const IveLogo = require("./ive-logo.png");
export const Modulo1 = require("./modulo1.png");
export const Modulo2 = require("./modulo2.png");
export const Modulo3 = require("./modulo3.png");
export const Modulo4 = require("./modulo4.png");
export const Modulo5 = require("./modulo5.png");
export const Modulo6 = require("./modulo6.png");
export const Modulo7 = require("./modulo7.png");
export const Modulo8 = require("./modulo8.png");
export const Modulo9 = require("./modulo9.png");
export const Modulo10 = require("./modulo10.png");