import React, { useState, useEffect } from "react";

import mainLogo from "../../images/navBarLogo.png";

import mainLogoES from '../../images/navBarLogo-es.png'

import { Navbar, Nav, Button } from "react-bootstrap";

import { Main, Line, ImgsContainer } from './styles'

import { FormattedMessage } from 'gatsby-plugin-intl';

import Language from '../../languages/language';

const Example = (lang) => { 

    const  valor = lang.lang;
    
    const [logo, setLogo] = useState(mainLogo);   

    useEffect(() => {
        valor === "es" ? setLogo(mainLogoES) : setLogo(mainLogo)
    }, [valor])

    return (
        <div>
            <Main>
                <ImgsContainer className="lang-img">
                    <Language />   
                </ImgsContainer>
                <Navbar
                    collapseOnSelect
                    expand="md"
                    className="main-nav navbar-expand-md navbar-dark"
                >
                    <div className="container">
                        <Navbar.Brand href="/">
                            <img
                                alt="Home Page"
                                src={logo}
                                width="219"
                                height="60"
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <ul className="navbar-nav mb-auto mt-0 ml-auto">
                                {/*<li className="nav-item active">
                    <Nav.Link href="#Ebook">E-book</Nav.Link>
    </li>*/}
                                <li className="nav-item">
                                    <Nav.Link href="#Depoimentos">
                                        <FormattedMessage id="depoimentos"/>
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href="#Metodologia">
                                        Metodologia
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Button className="navbar-button" href="#form">
                                     <FormattedMessage id="conversar"/>
                                    </Button>
                                </li>
                            </ul>
                        </Navbar.Collapse>
                    </div>
                </Navbar>

                <Line></Line>
            </Main>
        </div>
    );
};

export default Example;
