import React, { useState, useEffect } from 'react'
import {Col, Row, Form} from "react-bootstrap";

import emailjs from 'emailjs-com';

import {Container, Title, Button} from './styles';

import { FormattedMessage } from "gatsby-plugin-intl"

function ContactForm(lang) {
    const value_lang = lang.lang;

    const [ name, setName ] = useState("Nome")
    const [ email, setEmail] = useState("Email")

    useEffect(() => {
        value_lang === "es" ? setName("Coloque su nombre") : setName("Seu nome");
        value_lang === "es" ? setEmail("Coloque su email") : setEmail("Insira seu e-mail");
    }, [value_lang])

    function alertMsg(){
        if(value_lang === "es"){
            return alert('Gracias! Estaremos en contacto!');
        }

        return alert('Obrigado! Entraremos em contato!');
    }

    function sendEmail(e) {
        e.preventDefault();


        emailjs.sendForm('service_wovmga5', 'template_a71shq9', e.target, 'user_gBwmOwg2fyLExYWDvNIza')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    return (

        <Container fluid id="form">
            <Row className="spaced-row">
                <Col xs={{ offset: 1, span: 10 }} md={{ offset: 3, span: 6 }}>
                    <div className="second-title">
                        <Title>
                            <FormattedMessage id="form-contact.title" />
                        </Title>
                    </div>
                </Col>
                <Col xs={{ offset: 2, span: 8 }} md={{ offset: 4, span: 4 }}>
                
                <Form onSubmit={sendEmail}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control type="email" placeholder={email} name="from_email" required/>
                        <Form.Text className="text-muted">
                            <FormattedMessage id="form-contact.email" />
                        </Form.Text>
                    </Form.Group>
                    
                    <Form.Group controlId="formBasicNome">
                        <Form.Label>
                            <FormattedMessage id="form-contact.name"/>
                        </Form.Label>
                        <Form.Control type="text" placeholder={name} name="from_name" required/>
                    </Form.Group>
                    
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>
                            <FormattedMessage id="form-contact.fone"/>
                        </Form.Label>
                        <Form.Control type="text" placeholder="82999999999" name="from_number" required/>
                    </Form.Group>
                    
                    <Button
                        type="submit"
                        className="mb-2"
                        onClick={alertMsg}
                    >
                        Enviar
                    </Button>
                </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactForm