import React from "react";
import SEO from "../components/seo";
import NavBar from "../components/Navbar";
import VideoSection from "../components/VideoSection/videoSection.js";
import ProblemaSection from "../components/ProblemasSection";
import Ebook from "../components/Ebook/ebook.js";
import Depoimentos from "../components/Depoimentos";
import Modulos from "../components/Modulos";
import Metodologia from "../components/Metodologia";
import Empresas from '../components/Empresas';
import VideoDepoimentos from "../components/VideoDepoimentos";
import Razoes from "../components/Razoes";
import Duvidas from "../components/Duvidas";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

import { useIntl }  from 'gatsby-plugin-intl'

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "../fonts/GothamRnd-Medium_1.otf";

const IndexPage = () => {
    const intl = useIntl();
    return(
        <div>
            <SEO
                title={intl.formatMessage({ id: "main-title" })}
            />
            <NavBar
                lang={intl.locale}
            />
            {/* <VideoSection /> */}
            <ProblemaSection />
            {/*<Ebook />*/}
            {/* <Depoimentos /> */}
            <Metodologia />
            <Empresas />
            <Modulos />
            {/* <VideoDepoimentos /> */}
            {/* <Razoes /> */}
            {/* <Duvidas /> */}
            <ContactForm lang={intl.locale} />
            <Footer lang={intl.locale}/>
        </div>   
    )
}

export default IndexPage
