import styled from 'styled-components';

export const ModulosBody = styled.div`
    color: white;
    background-color: #04273bff;
    padding-top: 5%;
    padding-bottom: 3%;

    .row{
        padding: 4vh 0;
    }
`