import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";

import { Main, MetoDescription, Trouble, ProductsInfo, Lista, ProblemasBtn } from './styles';

import { FormattedMessage } from 'gatsby-plugin-intl';

function ProblemaSection() {
    return (
        <Main>
            <Container fluid>
                {/*<Row className="spaced-row">
            <Col
            className="justify-content-md-center"
            xs={{ offset: 1, span: 10 }}
            md={{ offset: 2, span: 8 }}
            >
            <div className="pos-vid-title">
                <h4>Perder tempo é perder dinheiro</h4>
            </div>
            </Col>
    </Row>*/}
                <Row className="spaced-row">
                    <Col
                        className="justify-content-md-center"
                        xs={{ offset: 1, span: 10 }}
                        md={{ offset: 1, span: 10 }}
                    >
                        <div className="big-title">
                            <FormattedMessage id="vendas-title"/>
                        </div>
                    </Col>
                </Row>
                <Row className="spaced-row">
                    <Col
                        className="justify-content-md-center"
                        xs={{ offset: 1, span: 10 }}
                        md={{ offset: 2, span: 8 }}
                    >
                        <div className="second-title">
                            <h2>
                                <FormattedMessage id="vendas-stitle" />
                            </h2>
                        </div>
                    </Col>
                </Row>
                <Row className="spaced-row">
                    <Col
                        className="justify-content-md-center"
                        xs={{ offset: 1, span: 10 }}
                        md={{ offset: 1, span: 10 }}
                    >
                        <MetoDescription className="metodologia-description">
                            <FormattedMessage id="vendas-description"/>
                        </MetoDescription>
                        
                        <ProductsInfo className="list-title">
                            <FormattedMessage id="lista-title" />
                        </ProductsInfo>
                    </Col>
                </Row>

                <Lista>
                    <Row className="lista-metodologia">
                        <Col
                            className="justify-content-md-center"
                            xs={{ offset: 1, span: 5 }}
                            md={{ offset: 2, span: 4 }}
                        >
                            <Row>
                                <CheckCircleFill size="6%" color="#82eb5cff" />
                                <p><FormattedMessage id="empresas.software"/></p>
                            </Row>
                            <Row>
                                <CheckCircleFill size="6%" color="#82eb5cff" />
                                <p><FormattedMessage id="empresas.metais"/></p>
                            </Row>
                            <Row>
                                <CheckCircleFill size="6%" color="#82eb5cff" />
                                <p><FormattedMessage id="empresas.elevadores"/></p>
                            </Row>
                            <Row>
                                <CheckCircleFill size="6%" color="#82eb5cff" />
                                <p><FormattedMessage id="empresas.automoveis"/></p>
                            </Row>
                        </Col>
                        <Col className="justify-content-md-center" xs={5} md={4}>
                            <Row>
                                <CheckCircleFill size="6%" color="#82eb5cff" />
                                <p><FormattedMessage id="empresas.manutencao"/></p>
                            </Row>
                            <Row>
                                <CheckCircleFill size="6%" color="#82eb5cff" />
                                <p><FormattedMessage id="empresas.ind_maquinas"/></p>
                            </Row>
                            <Row>
                                <CheckCircleFill size="6%" color="#82eb5cff" />
                                <p><FormattedMessage id="empresas.ind_medicas"/></p>
                            </Row>
                            <Row>
                                <CheckCircleFill size="6%" color="#82eb5cff" />
                                <p><FormattedMessage id="empresas.cons_civil"/></p>
                            </Row>
                        </Col>
                    </Row>
                </Lista>
                <Row className="spaced-row">
                    <Col
                        className="justify-content-md-center"
                        xs={{ offset: 1, span: 10 }}
                        md={{ offset: 2, span: 8 }}
                    >
                        <div className="second-title">
                            <Trouble>
                                <FormattedMessage id="dificuldade" />
                            </Trouble>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col
                        className="justify-content-md-center"
                        xs={12}
                        md={{ offset: 3, span: 6 }}
                    >
                        <ProblemasBtn>
                            <Button className="problemas-button" href="#form">
                                <FormattedMessage id="conversar-btn" />
                            </Button>
                        </ProblemasBtn>
                    </Col>
                </Row>
            </Container>
        </Main>
    );
}

export default ProblemaSection;
