import React from "react";
import { Container, Col, Row, Button, Image, Form } from "react-bootstrap";
import "./ebook.css";
import EbookCover from "../../images/book-cover.png";

function Ebook() {
    return (
        <Container id="Ebook" fluid className="ebook-background">
            <Row>
                <Col className="justify-content-md-center" xs={12} md={5}>
                    <Image
                        alt="Ebook Cover"
                        fluid
                        className="ebook-cover"
                        src={EbookCover}
                    />
                </Col>
                <Col
                    className="ebook-text justify-content-md-center"
                    xs={12}
                    md={7}
                >
                    <div className="ebook-white-text">
                        Não perca essa oportunidade!
                    </div>
                    <div className="ebook-title">Cadastre agora seu email</div>
                    <div className="ebook-subtitle">
                        Eu quero enviar de graça meu novo e-book, o mais rápido
                        possível!
                    </div>
                    <Form>
                        <Form.Row className="align-items-center form">
                            <Col xs="auto">
                                <Form.Label htmlFor="inlineFormInput" srOnly>
                                    Name
                                </Form.Label>
                                <Form.Control
                                    className="mb-2 input-email"
                                    id="inlineFormInput"
                                    placeholder="Digite seu email..."
                                    type="email"
                                />
                            </Col>
                            <Col xs="auto">
                                <Button
                                    type="submit"
                                    className="mb-2 send-button"
                                    variant="success"
                                >
                                    Enviar
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Ebook;
