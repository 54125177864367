import React, { useState, useEffect } from "react";
import { Navbar, Image } from "react-bootstrap";

import mainLogo from "../../images/footerLogo.png";
import mainLogoES from '../../images/footerLogo-es.png';

import { FormattedMessage } from 'gatsby-plugin-intl';

import {FooterContainer, FooterText } from './styles';

function Footer(lang) {

    const  valor = lang.lang;
    
    const [logo, setLogo] = useState(mainLogo);   

    useEffect(() => {
        valor === "es" ? setLogo(mainLogoES) : setLogo(mainLogo)
    }, [valor])


    return (
        <Navbar sticky="bottom" className="nav-color">
            <FooterContainer className="container">
                <Navbar.Brand href="#home">
                    <Image
                        fluid
                        alt="Footer Logo"
                        src={logo}
                        width="182"
                        height="50"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Text>
                    <FooterText>
                        {"\u00A9"} Copyright 2020 | <FormattedMessage id="direitos" /> | <strong>+55 47 99141-2309</strong>
                    </FooterText>
                </Navbar.Text>
            </FooterContainer>
        </Navbar>
    );
}

export default Footer;
