import React from "react";
import { Container, Image, Col, Row } from "react-bootstrap";
import {
    Modulo1,
    Modulo2,
    Modulo3,
    Modulo4,
    Modulo5,
    Modulo6,
    Modulo7,
    Modulo8,
    Modulo9,
    Modulo10,
} from "../../images/modulos/index.js";

import {FormattedMessage} from 'gatsby-plugin-intl';

import '../../languages/es.json';

import { ModulosBody } from './styles';

function Modulos() {
    return (
        <ModulosBody>
        <Container fluid id="Metodologia">
            <Row>
                <Col
                    className="justify-content-md-center"
                    xs={12}
                    md={{ offset: 1, span: 10 }}
                >
                    <p className="ive-text">
                        <FormattedMessage id="modulos-title" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo1}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 1</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod1.title" />
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod1.description" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo2}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 2</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod2.title" />
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod2.description" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo3}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 3</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod3.title" />
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod3.description" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo4}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 4</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod4.title" />
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod4.description" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo5}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 5</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod5.title" />
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod5.description" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo6}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 6</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod6.title" />
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod6.description" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo7}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 7</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod7.title" />
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod7.description" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo8}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 8</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod8.title" />
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod8.description" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo9}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 9</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod9.title" />
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod9.description" />
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1, span: 2 }} md={{ offset: 2, span: 1 }}>
                    <Image
                        fluid
                        src={Modulo10}
                        width="84"
                        height="84"
                        className="d-inline-block align-top"
                    />
                </Col>
                <Col xs={{ span: 8 }} md={{ span: 7 }}>
                    <h5 className="modulo-number">MÓDULO 10</h5>
                    <h5 className="modulo-ask">
                        <FormattedMessage id="modulos.mod10.title" />
                        {" "}
                    </h5>
                    <p className="modulo-description">
                        <FormattedMessage id="modulos.mod10.description" />
                    </p>
                </Col>
            </Row>
        </Container>
        </ModulosBody>
    );
}

export default Modulos;
