import styled from 'styled-components';

export const Bg = styled.div`
    background-color: #ededed;
    padding-top: 2ch;
    padding-bottom: 2ch;

    .card {
        height: 100%;
        border-radius: 15px !important;
        -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.5);
    }

    .card-text {
        text-align: justify;
        color: #04273bff;
    }
      
      .cardFooter {
        font-weight: bold;
        color: #04273bff;
        padding-bottom: 1ch;
    }
      
      .card-item {
        padding-bottom: 3vh;
        padding-top: 3vh;
    }
      
`

export const DepoimentosBtn = styled.div`
    a{
        border: 0px !important;
        font-weight: bold !important;
        width: 100%;
        margin-top: 5ch;
        padding-top: 1ch;
        padding-bottom: 1ch;
        border-radius: 25px !important;
        background: #00e78d;
        background: -moz-linear-gradient(left, #00e78d 0%, #00f774 50%, #50ff56 100%);
        background: -webkit-linear-gradient(
        left,
        #00e78d 0%,
        #00f774 50%,
        #50ff56 100%
        );
        background: linear-gradient(to right, #00e78d 0%, #00f774 50%, #50ff56 100%);
    }
`