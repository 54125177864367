import styled from 'styled-components';

export const Main = styled.div`
    padding-top: 6vh;
    background-color: #ededed;

    .spaced-row{
        padding: 0;
    }
`

export const MetoDescription = styled.div`
    padding-top: 6vh;
    padding-bottom: 6vh;
    font-size: 22px;
    text-align: center;
    line-height: 44px;
`

export const Trouble = styled.p`
    font-size: 22px;
    color: #212529;
`

export const ProductsInfo = styled.p`
    text-align: center;
    font-weight: bold;
    color: #04273bff;
`

export const Lista = styled.div`

    .lista-metodologia {
        justify-content: space-evenly ;
        height: max-content;
    }

    .lista-metodologia .row {
        padding: 10px 0;
        align-items: center;
      }

    .lista-metodologia p {
        margin-bottom: 0;
        padding-left: 4%;
        width: 90%;
    }
`

export const ProblemasBtn = styled.div`
    a{
        border: 0px !important;
        font-weight: bold !important;
        width: 100%;
        margin-bottom: 5ch;
        padding-top: 1ch;
        padding-bottom: 1ch;
        border-radius: 25px !important;
        background: #00e78d;
        background: -moz-linear-gradient(left, #00e78d 0%, #00f774 50%, #50ff56 100%);
        background: -webkit-linear-gradient(
        left,
        #00e78d 0%,
        #00f774 50%,
        #50ff56 100%
        );
        background: linear-gradient(to right, #00e78d 0%, #00f774 50%, #50ff56 100%);
    }
`