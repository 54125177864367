import React, { useState, useEffect } from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

import {Brasil, Espanha} from '../images/index';

import browserLang from 'browser-lang'

import {Image} from 'react-bootstrap';

const languageName = {
  pt: <Image fluid src={Brasil} width="35" height="30"/>,
  es:  <Image fluid src={Espanha} width="35" height="35"/>
}

const Language = () => {

  const [ init, setInit] = useState(1);
  const languages = ['pt', 'es'];

  const getLanguage = () => {
    if (init) {  
      const language = browserLang({
        languages: languages, 
        fallback: 'pt',
      });
    }
  };

  useEffect(() => {
    getLanguage()
  });    
  
  return (
    <div>
      <IntlContextConsumer>
        {
        ({ languages }) =>
          languages.map(language => (
            <a
              key={language}
              onClick={() => {
                setInit(0)
                changeLocale(language)
              }}
            >
              {languageName[language]}
            </a>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language