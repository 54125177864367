import React from "react";
import { Container, Col, Row, Card, Figure, Button } from "react-bootstrap";
import Depoimentos1 from "../../images/depoimento1.jpeg";
import Depoimentos2 from "../../images/depoimento2.jpeg";
import Depoimentos3 from "../../images/depoimento3.jpeg";
import Depoimentos4 from "../../images/depoimento4.jpeg";

import {Bg, DepoimentosBtn } from './styles';

import { FormattedMessage } from 'gatsby-plugin-intl';

function Depoimentos() {
    return (
        <Bg>
            <Container fluid id="Depoimentos">
                {/*
            <Row className='spaced-row'>
                <Col className="justify-content-md-center" xs={{offset: 1, span: 10}} md={{offset: 2, span: 8}}>
                    <div className="big-title">
                        Quem já fez o curso?
                    </div>
                </Col>
            </Row>
    */}
                <Row className="spaced-row">
                    <Col
                        className="justify-content-md-center"
                        xs={{ offset: 1, span: 10 }}
                        md={{ offset: 2, span: 8 }}
                    >
                        <div className="big-title">
                            <FormattedMessage id="feedback-title" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col
                        className="justify-content-md-center card-item"
                        xs={12}
                        md={{ offset: 1, span: 5 }}
                    >
                    
                    
                        <Card className="text-center">
                            <Figure style={{ paddingTop: "3vh" }}>
                                <Figure.Image
                                    alt="Card Avatar"
                                    roundedCircle={true}
                                    width={75}
                                    height={95}
                                    src={Depoimentos1}
                                />
                            </Figure>
                            <Card.Body>
                                <Card.Text>
                                    <FormattedMessage id="depoimentos-pessoas.Wagner" />
                                </Card.Text>
                            </Card.Body>
                            <footer className="cardFooter">
                                Wagner Costa, CashWay
                            </footer>
                        </Card>
                    
                    </Col>
                    

                    <Col
                        className="justify-content-md-center card-item"
                        xs={12}
                        md={5}
                    >
                    
                    
                        <Card className="text-center">
                            <Card.Body>
                                <Figure style={{ paddingTop: "3vh" }}>
                                    <Figure.Image
                                        alt="Card Avatar"
                                        roundedCircle={true}
                                        width={75}
                                        height={95}
                                        src={Depoimentos2}
                                    />
                                </Figure>
                                <Card.Text>
                                    <FormattedMessage id="depoimentos-pessoas.Paulo" />
                                </Card.Text>
                            </Card.Body>
                            <footer className="cardFooter">
                                Paulo César Misino, Spin Selling
                            </footer>
                        </Card>
                        
                    </Col>
                </Row>
                <Row>
                    <Col
                        className="justify-content-md-center card-item"
                        xs={12}
                        md={{ offset: 1, span: 5 }}
                    >
                        <Card className="text-center">
                            <Figure style={{ paddingTop: "3vh" }}>
                                <Figure.Image
                                    alt="Card Avatar"
                                    roundedCircle={true}
                                    width={75}
                                    height={95}
                                    src={Depoimentos3}
                                />
                            </Figure>
                            <Card.Body>
                                <Card.Text>
                                    <FormattedMessage id="depoimentos-pessoas.Gilson" />
                                </Card.Text>
                            </Card.Body>
                            <footer className="cardFooter">
                                Gilson Teixeira Ramos, CISA "We Care Health Care"
                            </footer>
                        </Card>
                    </Col>

                    <Col
                        className="justify-content-md-center card-item"
                        xs={12}
                        md={5}
                    >
                    
                    
                        <Card className="text-center">
                            <Card.Body>
                                <Figure style={{ paddingTop: "3vh" }}>
                                    <Figure.Image
                                        alt="Card Avatar"
                                        roundedCircle={true}
                                        width={75}
                                        height={95}
                                        src={Depoimentos4}
                                    />
                                </Figure>
                                <Card.Text>
                                    <FormattedMessage id="depoimentos-pessoas.Cristiana" />
                                </Card.Text>
                            </Card.Body>
                            <footer className="cardFooter">
                                Cristiana Angélica, WinSales
                            </footer>
                        </Card>
                    </Col>
                </Row>

                <Row>
                <Col
                    className="justify-content-md-center"
                    xs={12}
                    md={{ offset: 3, span: 6 }}
                >
                    <DepoimentosBtn>
                        <Button className="problemas-button" href="#form">
                            <FormattedMessage id="conversar-btn" />
                        </Button>
                    </DepoimentosBtn>
                </Col>
            </Row>
            </Container>
        </Bg>
    );
}

export default Depoimentos;
