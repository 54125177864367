import React from "react";
import Video from '../Video/video';

import { Container, Col, Row, Button } from "react-bootstrap";

import { Title, Lista, ProblemasBtn, Main } from './styles'

import { FormattedMessage } from 'gatsby-plugin-intl';

function ProblemaSection() {
    return (
        <Main>
        <Container fluid>
            <Row className="spaced-row">
                <Col
                    className="justify-content-md-center"
                    xs={{ offset: 1, span: 10 }}
                    md={{ offset: 2, span: 8 }}
                >
                    <Title className="big-title">
                        {" "}
                        <FormattedMessage id="fluxo"/>
                    </Title>
                </Col>
            </Row>
            <Row className="spaced-row">
                <Col
                className="justify-content-md-center"
                xs={{ offset: 1, span: 10 }}
                md={{ offset: 2, span: 8 }}
                >
                <div className="second-title">
                    <Video videoId='56NEmpoX47I'/>
                </div>
                </Col>
            </Row>
       
            <Row className="spaced-row">
                <Col
                    className="justify-content-md-center"
                    xs={{ offset: 0, span: 12 }}
                    md={{ offset: 2, span: 8 }}
                >
                    <Lista className="lista-problemas">
                        <ul>
                            <li><FormattedMessage id="problemas.pro1" /></li>
                            
                            <li><FormattedMessage id="problemas.pro2" /></li>
                            
                            <li><FormattedMessage id="problemas.pro3" /></li>
                            
                            <li><FormattedMessage id="problemas.pro4" /></li>
                            
                            <li><FormattedMessage id="problemas.pro5" /></li>

                            <li><FormattedMessage id="problemas.pro6" /></li>

                            <li><FormattedMessage id="problemas.pro7" /></li>

                            <li><FormattedMessage id="problemas.pro8" /></li>

                            <li><FormattedMessage id="problemas.pro9" /></li>

                            <li><FormattedMessage id="problemas.pro10" /></li>

                            <li><FormattedMessage id="problemas.pro11" /></li>

                            <li><FormattedMessage id="problemas.pro12" /></li>

                            <li><FormattedMessage id="problemas.pro13" /></li>
                        </ul>
                    </Lista>
                </Col>
            </Row>
            <Row>
                <Col
                    className="justify-content-md-center"
                    xs={12}
                    md={{ offset: 3, span: 6 }}
                >
                    <ProblemasBtn>
                        <Button className="problemas-button" href="#form">
                            <FormattedMessage id="conversar-btn" />
                        </Button>
                    </ProblemasBtn>
                </Col>
            </Row>
        </Container>
        </Main>
    );
}

export default ProblemaSection;
