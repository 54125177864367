import styled from 'styled-components';

export const Main = styled.div`
    background: #fff

    .video-section {
        display: flex;
    }
`

export const Title = styled.div`
    text-align: center;
    color: #04273bff;
    font-weight: bold;
`

export const Lista = styled.div`
    color: #04273bff;
`

export const ProblemasBtn = styled.div`

    justify-content: center;

    a{
        border: 0px !important;
        font-weight: bold !important;
        width: 100%;
        margin-bottom: 5ch;
        padding-top: 1ch;
        padding-bottom: 1ch;
        border-radius: 25px !important;
        background: #00e78d;
        background: -moz-linear-gradient(left, #00e78d 0%, #00f774 50%, #50ff56 100%);
        background: -webkit-linear-gradient(
        left,
        #00e78d 0%,
        #00f774 50%,
        #50ff56 100%
        );
        background: linear-gradient(to right, #00e78d 0%, #00f774 50%, #50ff56 100%);
    }
`