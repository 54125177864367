import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import { AnswerDuvida, TitleDuvida } from './styles';

function Duvidas() {
    return (
        <Container fluid className="duvidas">
            <Row className="spaced-row">
                <Col
                    className="justify-content-md-center"
                    xs={{ offset: 1, span: 10 }}
                    md={{ offset: 2, span: 8 }}
                >
                    <div className="big-title">Você tem alguma dúvida?</div>
                </Col>
            </Row>
            <Row className="spaced-row">
                <Col
                    className="justify-content-md-center"
                    xs={12}
                    md={{ offset: 2, span: 8 }}
                >
                    <div className="second-title">
                        <h2>
                            Veja a resposta para as principais dúvidas
                            frequentes abaixo
                        </h2>
                    </div>
                </Col>
            </Row>
            <Row className="spaced-row">
                <Col xs={{ offset: 1, span: 10 }} md={{ offset: 2, span: 4 }}>
                    <TitleDuvida>
                        1. Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit?
                    </TitleDuvida>
                    <AnswerDuvida>
                        Suspendisse auctor gravida purus vel aliquam. Donec nec
                        pulvinar dui. Sed arcu magna, cursus id eros nec,
                        vulputate fringilla dui. Aenean eget ipsum tincidunt,
                        egestas odio nec, efficitur nisl.
                    </AnswerDuvida>
                </Col>
                <Col xs={{ offset: 1, span: 10 }} md={4}>
                    <TitleDuvida>
                        1. Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit?
                    </TitleDuvida>
                    <AnswerDuvida>
                        Suspendisse auctor gravida purus vel aliquam. Donec nec
                        pulvinar dui. Sed arcu magna, cursus id eros nec,
                        vulputate fringilla dui. Aenean eget ipsum tincidunt,
                        egestas odio nec, efficitur nisl.
                    </AnswerDuvida>
                </Col>
            </Row>
        </Container>
    );
}

export default Duvidas;
