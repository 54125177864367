import React from "react";
import Video from "../Video/video.js";
import { Container, Col, Row } from "react-bootstrap";
import "./videoSection.css";

function VideoSection() {
    return (
        <Container fluid>
        {/* <Row>
        <Col
          className="justify-content-md-center"
          xs={{ offset: 1, span: 10 }}
          md={{ offset: 3, span: 6 }}
        >
          <div className="red-title">
            <h3>Quer aprender a vender todo dia?</h3>
          </div>
        </Col>
      </Row> */}
      {/* <Row className="spaced-row">
        <Col
          className="justify-content-md-center"
          xs={{ offset: 1, span: 10 }}
          md={{ offset: 2, span: 8 }}
        >
          <div className="big-title">Todo dia, Toda semana, Todo mês</div>
        </Col>
      </Row> */}
      {/* <Row className="spaced-row">
        <Col
          className="justify-content-md-center"
          xs={{ offset: 1, span: 10 }}
          md={{ offset: 2, span: 8 }}
        >
          <div className="second-title">
            <h2>Você precisa conhecer nossa metodologia exclusiva de vendas</h2>
          </div>
        </Col>
      </Row> */}
        {/* <Row>
            <Col className="justify-content-md-center" xs={12} md={{offset: 2, span: 8}}>
                <Video videoId='eEzD-Y97ges'/>
            </Col>
        </Row> */}
        {/* <Row className='spaced-row'>
            <Col className="justify-content-md-center" xs={{offset: 1, span: 10}} md={{offset: 2, span: 8}}>
                <div className="pos-vid-title">
                    <h4>
                        Atenção: empreendedor, empresário, profissionais de vendas...
                    </h4>
                </div>
            </Col>
        </Row> */}
        </Container>
    );
}

export default VideoSection;
