import React from 'react';
import {Col, Row, Container, Image} from 'react-bootstrap';

import {Becomex, Cisa, Edesoft, Eleinmsa, Gedee, Hansa, Imap, Infox, Libercard, Logica, SoftExpert} from '../../images/empresas';

import {Main, Title} from './styles';

import { FormattedMessage } from 'gatsby-plugin-intl';



const Empresas = () => {
    return(
        <Main>
            <Container>

                <Title className="title-empresas">
                    <FormattedMessage id="empresas-title" />
                </Title>

                <Row className="justify-content-md-center row-img">
                    <Col>
                    <a href="https://becomex.com.br/" target="blank">
                        <Image 
                            src={Becomex}
                            width="140"
                            height="80"
                            fluid
                        />
                    </a>
                    </Col>
                    <Col>
                    <a href="https://www.softexpert.com/" target="blank">
                        <Image 
                            src={SoftExpert}
                            width="180"
                            height="100"
                            fluid
                        />
                    </a>
                    </Col>
                    <Col>
                    <a href="https://edesoft.com.br/" target="blank">
                        <Image 
                            src={Edesoft}
                            width="140"
                            height="80"
                            fluid
                        />
                    </a>
                    </Col>
                </Row>

                <Row className="justify-content-md-center row-img">
                    <Col>
                    <a href="http://www.eleinmsa.com/" target="blank">
                        <Image 
                            src={Eleinmsa}
                            width="140"
                            height="80"
                            fluid
                        />
                    </a>
                    </Col>
                    <Col>
                    <a href="http://www.gedeecmedical.com/" target="blank">
                        <Image 
                            src={Gedee}
                            width="150"
                            height="80"
                            fluid
                        />
                    </a>
                    </Col>
                    <Col>
                    <a href="https://www.hansa.com.bo/" target="blank">
                        <Image 
                            src={Hansa}
                            width="120"
                            height="80"
                            fluid
                        />
                    </a>
                    </Col>
                </Row>

                <Row className="justify-content-md-center row-img">
                    <Col>
                    <a href="https://www.imap.com.br/" target="blank">
                        <Image 
                            src={Imap}
                            width="150"
                            height="80"
                            fluid
                        />
                    </a>
                    </Col>
                    <Col>
                    <a href="https://infoxnet.com.br/" target="blank">
                        <Image 
                            src={Infox}
                            width="120"
                            height="80"
                            fluid
                        />
                    </a>
                    </Col>
                    <Col>
                    <a href="https://www.logicainfo.com.br/" target="blank">
                        <Image 
                            src={Logica}
                            width="120"
                            height="80"
                            fluid
                        />
                    </a>
                    </Col>
                </Row>

                <Row className="justify-content-md-center row-img">

                    <Col>
                    <a href="https://www.libercard.com.br/libercard/" target="blank">
                        <Image 
                            src={Libercard}
                            width="110"
                            fluid
                        />
                    </a>
                    </Col>
                    <Col>
                    <a href="https://www.cisabrasile.com.br/" target="blank">
                        <Image 
                            src={Cisa}
                            width="120"
                            height="80"
                            fluid
                        />
                    </a>
                    </Col >

                    <Col>{" "}</Col>

                </Row>
            </Container>
        </Main>
    )
}

export default Empresas;