import styled from 'styled-components';

export const Main = styled.div`
    background: #00f774;
    width: 100%;
    
    .container{
        padding: 2% 0;    
    }

    .container .row{
        align-items: center ;
        padding-top: 15px;
        flex-wrap: wrap;
    }

    .container .col{
        align-items: center ;
        text-align: center;
    }

    .col img{
        padding-top: 15px;
        padding-bottom: 15px;
    }
`

export const Title = styled.h1`
    color: #04273bff;
    text-align: center;
    font-size: 5vw;
    padding: 30px 0;
    font-weight: 600;
`