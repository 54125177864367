import styled from 'styled-components';

// const media = {
//     mobile: '@media (max-width: 768px)'
// }

export const Container = styled.div`
    background-color: #04273bff;
    color: white;
    padding: 5vh 0;

    .row{
        width: 100%;
        margin-left: 0;
    }
`
export const Title = styled.h2`
    color: white !important;
    padding-bottom: 4vh;
    text-align: center;
`

export const Button = styled.button`
    text-decoration: none;
    border: 0px !important;
    font-weight: bold !important;
    width: 100%;
    color: white !important;
    justify-self: center;
    margin-bottom: 5ch;
    padding-top: 1ch;
    padding-bottom: 1ch;
    border-radius: 25px !important;
    background: #00e78d;
    background: -moz-linear-gradient(left, #00e78d 0%, #00f774 50%, #50ff56 100%);
    background: -webkit-linear-gradient(
        left,
        #00e78d 0%,
        #00f774 50%,
        #50ff56 100%
    );
    background: linear-gradient(to right, #00e78d 0%, #00f774 50%, #50ff56 100%);
`