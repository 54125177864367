export const Becomex = require('./becomex.png')
export const Cisa = require('./cisa.png')
export const Edesoft = require('./edesoft.png')
export const Eleinmsa = require('./eleinmsa.png')
export const Gedee = require('./gedee.png')
export const Hansa = require('./hansa.png')
export const Imap = require('./imap.png')
export const Infox = require('./infox.png')
export const Libercard = require('./libercard.png')
export const Logica = require('./logica.png')
export const SoftExpert = require('./softexpert.png')