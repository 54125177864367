import styled from 'styled-components';

export const FooterContainer = styled.div`
    
    .navbar-ligth{
        background: black,
    }

    height: 15vh;
    background-color: #ededed!important;
`

export const FooterText = styled.p`
    margin-bottom: 0;
    color: black;
`